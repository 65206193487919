import { FaqSection } from '@saatva-bits/pattern-library.modules.faq'
import { SAATVA_CLASSIC } from '@/constants/product-codes'
import ProductOverview from '@/components/ProductOverview'
import SliderByContent from '@/components/SliderByContent'
import SupportValueProps from '@/components/SupportValueProps'
import MattressLayer from '@/components/MattressLayer'
import AdditionalFeaturesPDP from '@/components/AdditionalFeaturesPDP'
import ProductValueProps from '@/components/ProductValueProps/ProductValueProps'
import ImageByContent from '@/components/ImageByContent'
import CrossSells from '@/components/CrossSells'
import PDPReviews from '@/components/PDPReviews'
import { ProductStickyNavSection, ProductStickyNavWrapper } from '@/components/ProductStickyNav'
import VideoByContent from '@/components/VideoByContent/VideoByContent'

const SaatvaClassic = () => {
    const productCode = SAATVA_CLASSIC

    return (
        <ProductStickyNavWrapper productCode={productCode}>
            <ProductStickyNavSection id="overview" key="overview">
                <ProductOverview productCode={productCode} />
                <ImageByContent
                    productCode={productCode}
                    backgroundColorClass='u-bgColor--contrast1'
                    imageSide='left'
                    sectionName='backAndJoint'
                    titleBelowImage={true}
                />
                <ProductValueProps productCode={productCode} />
                <SliderByContent productCode={productCode} imageSide='right' sectionName='classicSpecs' />
            </ProductStickyNavSection>
            <ProductStickyNavSection id="layers" key="layers">
                <MattressLayer productCode={productCode} className='u-paddingBottom--none' /> 
            </ProductStickyNavSection>
            <ProductStickyNavSection id="details" key="details">
                <VideoByContent
                    videoSide='left'
                    productCode={`${productCode}--comfort-level`}
                />
                <CrossSells
                    crossSellData='discoverOtherGreatOptions'
                    title='Discover other great options'
                    classes='u-bgColor--contrast-1'
                />
            </ProductStickyNavSection>
            <ProductStickyNavSection id="specs" key="specs">
                <FaqSection />
            </ProductStickyNavSection>
            <ProductStickyNavSection id="reviews" key="reviews">
                <PDPReviews productCode={productCode} />
                <AdditionalFeaturesPDP productCode={productCode} />
                <ImageByContent
                    productCode='made-to-order'
                    sectionName='notBedInBox'
                    imageSide='right'
                    backgroundColorClass='u-bgColor--contrast-1'
                    titleBelowImage={true}
                />
                <CrossSells />
                <SupportValueProps />
            </ProductStickyNavSection>
        </ProductStickyNavWrapper>
    )
}

export default SaatvaClassic
